import React, { useEffect, useState } from "react";
import Map from "@arcgis/core/Map";
import SceneView from "@arcgis/core/views/SceneView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import Polygon from "@arcgis/core/geometry/Polygon";
import PolygonSymbol3D from "@arcgis/core/symbols/PolygonSymbol3D";
import ExtrudeSymbol3DLayer from "@arcgis/core/symbols/ExtrudeSymbol3DLayer";
import "@arcgis/core/assets/esri/themes/light/main.css";
// import "font-awesome/css/font-awesome.min.css"; // Ensure Font Awesome is imported

const EsriMap = () => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  useEffect(() => {
    const map = new Map({
      basemap: "topo-vector",
      ground: "world-elevation"
    });

    const view = new SceneView({
      container: "viewDiv", 
       qualityProfile: "high",
      map: map,
      camera: {
        position: [21.146644, 42.644623, 1590], // Adjust camera position for a good view
        tilt: 60
      }
    });

    const graphicsLayer = new GraphicsLayer();
    map.add(graphicsLayer);

    function addBuildingWithSpaces(id, rings, floors, floorHeight, colors) {
      for (let i = 0; i < floors; i++) {
        const floorPolygon = new Polygon({
          rings: rings.map(ring =>
            ring.map(coord => [coord[0], coord[1], coord[2] + i * floorHeight])
          ),
          spatialReference: { wkid: 4326 }
        });

        const floorSymbol = new PolygonSymbol3D({
          symbolLayers: [
            new ExtrudeSymbol3DLayer({
              material: { color: colors[i % colors.length] },
              size: floorHeight
            })
          ]
        });

        const floorGraphic = new Graphic({
          geometry: floorPolygon,
          symbol: floorSymbol,
          attributes: {
            id: `${id}-floor-${i + 1}`,
            floor: i + 1
          }
        });

        graphicsLayer.add(floorGraphic);
      }
    }

    const colors = ["#87CEFA", "#D0D0D0"];



    //First row buildings
    addBuildingWithSpaces(
      "building-1",
      [
        [
          [21.147213825259435, 42.656485146804854, 590],
          [21.147452255475372, 42.65637196181751, 590],
          [21.147574261572487, 42.65650508610771, 590],
          [21.147736138943174, 42.6564265017594, 590],
        
          [21.14757702228271, 42.65624884486444, 590],
          [21.14743492831322, 42.65631768244891, 590],
          [21.147360284760254, 42.65623633416708, 590],
          [21.14728238992107, 42.65627098906225, 590],
          [21.146835908413625, 42.65576977236939, 590],
        
          [21.14708761453435, 42.65565514965468, 590],
          [21.14695295536007, 42.65550590158863, 590],
          [21.146812606361525, 42.65556866951053, 590],
          [21.14683726226668, 42.655595171502966, 590],
          [21.146547081229148, 42.65573605032582, 590],
        ]
        
      ],
      10,
      10,
      colors
    );

    addBuildingWithSpaces(
      "building-2",
      [
        [
          [21.147756028367798, 42.65693034406505, 500],
          [21.148097325845722, 42.656895155046506, 500],
          [21.148145171608792, 42.65691040221101, 500],
          [21.148169094655447, 42.6569279953579, 500],
          [21.1481690952191, 42.656965527851035, 500],
          [21.148055861813017, 42.657051149770766, 500],
          [21.148298284420196, 42.657297454717, 500],
          [21.14832539731783, 42.65730801048866, 500],
          [21.148366865578826, 42.65739480406495, 500],
          [21.148164318468993, 42.65744758589654, 500],
          [21.147756028367798, 42.65693034406505, 500],
        ]
      ],
      15,
      10,
      colors
    );

    addBuildingWithSpaces(
    "building-3",
      [
        [
          [21.148288699361395, 42.65757641709598, 590],
          [21.148430793150503, 42.65747689438811, 590],
          [21.14891482141279, 42.657890971353105, 590],
          [21.14877150364299, 42.6579939904215, 590],
        ]
      ],
      10,
      10,
      colors
    );


    //Second row buildings
    addBuildingWithSpaces(
      "building-4",
      [
        [
          [21.147712013037417, 42.65764526854311, 590],
          [21.147921225330148, 42.65757031203079, 590],
          [21.148924371451702, 42.65842638892597, 590],
          [21.148822954562476, 42.65845405766334, 590],
          [21.147927902354674, 42.65770414501446, 590],
          [21.147836520433792, 42.65775795924478, 590],
        ]
        
      ],
      10,
      10,
      colors
    );

    addBuildingWithSpaces(
      "building-5",
      [
        [
          [21.147288269471453, 42.65691840125577, 590],
          [21.147868800561515, 42.65752830204758, 590],
          [21.147677417617114, 42.65761978837594, 590],
          [21.14707137002361, 42.65703099856487, 590],
          [21.146623397002635, 42.65695898210485, 590],
          [21.14662749160694, 42.65676952191845, 590]
        ]
      ],
      8,
      5,
      colors
    );

//Third row buildings
    addBuildingWithSpaces(
      "building-6",
      [
        [
          [21.14623679815277, 42.6569667115595, 590],
          [21.146209976063968, 42.65681285167876, 590],
          [21.146601578560613, 42.65681285167876, 590],
          [21.146606942978377, 42.656962766439115, 590],
        ]
      ],
      15,
      10,
      colors
    );

    addBuildingWithSpaces(
      "building-7",
      [
        [
          [21.14753498725126, 42.6577360052529, 590],
          [21.14860250638596, 42.65852895956052, 590],
          [21.14838256525771, 42.658623639996115, 590],
          [21.147320410540765, 42.65783463196121, 590],
        ]
      ],
      7,
      10,
      colors
    );

    //Fourth row buildings
    addBuildingWithSpaces(
      "building-8",
      [
        [
          [21.14530785962502, 42.65713544707659, 610],
          [21.145736355054506, 42.65752048659254, 610],
          [21.145919762912918, 42.65742020520094, 610],
          [21.145668856557887, 42.65718681990153, 610],
          [21.14591092724365, 42.65704392726157, 610],
          [21.145775171738862, 42.656916254231014, 610],
          [21.145531582076888, 42.65706056271149, 610],
          [21.14548759986218, 42.6570242947228, 610],
        ]
        
      ],
      15,
      10,
      colors
    );

    addBuildingWithSpaces(
      "building-9",
      [
        [
          [21.14574576136089, 42.65752787193991, 610],
          [21.145969725802463, 42.65773202973621, 610],
          [21.14614004606642, 42.65762847151751, 610],
          [21.146107859559844, 42.65759493834306, 610],
          [21.14635596388135, 42.65745094274138, 610],
          [21.146217830123973, 42.65732371347337, 610],
          [21.145972316879316, 42.65746794953007, 610],
          [21.14592806043278, 42.657429978041996, 610],
        ]
        
      ],
      12,
      10,
      colors
    );

    addBuildingWithSpaces(
      "building-10",
      [
        [
          [21.14597752282305, 42.657740660169075, 610],
          [21.146412549056723, 42.65814709866688, 610],
          [21.146584210425118, 42.65804649992834, 610],
          [21.14653660121748, 42.65800409060742, 610],
          [21.146799457687834, 42.65784974016043, 610],
          [21.14665465414947, 42.657721416049846, 610],
          [21.146406654827878, 42.65786392044664, 610],
          [21.14615290771154, 42.65763814058246, 610],
        ]
      ],
      15,
      10,
      colors
    );

    addBuildingWithSpaces(
      "building-11",
      [
        [
          [21.146473287664175, 42.65820431183742, 610],
          [21.146978855413014, 42.658662901506744, 610],
          [21.147149504463886, 42.65856555411035, 610],
          [21.147077736171465, 42.65849283667868, 610],
          [21.14732493806758, 42.658359130211366, 610],
          [21.14718937573745, 42.65822659632408, 610],
          [21.14694057899039, 42.65835795734636, 610],
          [21.14664712641693, 42.65810344511369, 610],
        ]
        
      ],
      13,
      10,
      colors
    );


    const tooltip = document.createElement("div");
    tooltip.style.position = "absolute";
    tooltip.style.padding = "5px";
    tooltip.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
    tooltip.style.color = "#fff";
    tooltip.style.borderRadius = "3px";
    tooltip.style.pointerEvents = "none";
    tooltip.style.display = "none";
    document.body.appendChild(tooltip);

    view.on("pointer-move", (event) => {
      if (!tooltipEnabled) {
        tooltip.style.display = "none";
        return;
      }

      view.hitTest(event).then((response) => {
        const results = response.results;
        if (results.length > 0) {
          const graphic = results.filter(result => result.graphic.layer === graphicsLayer)[0]?.graphic;
          if (graphic) {
            const geometry = graphic.geometry;
            const [longitude, latitude, altitude] = geometry.centroid.toArray();
            tooltip.innerHTML = `Altitude: ${altitude.toFixed(2)}m`;
            // tooltip.innerHTML = `Coordinates: ${longitude}, ${latitude}<br>Altitude: ${altitude.toFixed(2)}m`;
            tooltip.style.left = event.x + "px";
            tooltip.style.top = event.y + "px";
            tooltip.style.display = "block";
          } else {
            tooltip.style.display = "none";
          }
        } else {
          tooltip.style.display = "none";
        }
      });
    });

    view.on("pointer-leave", () => {
      tooltip.style.display = "none";
    });

    return () => {
      if (view) {
        view.destroy();
      }
      document.body.removeChild(tooltip);
    };
  }, [tooltipEnabled]);

  return (
    <div style={{ position: "relative", height: "100vh", width: "100%" }}>
      <div style={{ position: "absolute", top: "10px", right: "10px", zIndex: 1000 }}>
        <button
          onClick={() => setTooltipEnabled(!tooltipEnabled)}
          style={{
            padding: "10px 20px",
            backgroundColor: tooltipEnabled ? "#f44336" : "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer"
          }}
        >
          <i
            className={`fa ${tooltipEnabled ? "fa-times-circle" : "fa-info-circle"}`}
            aria-hidden="true"
          ></i>
          {" "}
          {tooltipEnabled ? "Disable Tooltip" : "Enable Tooltip"}
        </button>
      </div>
      <div id="viewDiv" style={{ height: "100%", width: "100%" }}></div>
    </div>
  );
};

export default EsriMap;
